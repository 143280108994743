<template>
  <div style="padding: 20px">
    <div>
      <i class="el-icon-warning-outline"></i>
      缓存列表是平台将藏品作为判断条件时，为了快速调用时使用。后续运营新活动时，若需用户拥有一定量的藏品才可参与，则这些藏品均会列入缓存列表名单。
    </div>
    <el-form style="padding-top: 20px " :model="form" ref="form" inline>
      <el-form-item label="藏品名称">
        <el-input
          v-model="form.name"
          @keyup.enter.native="onQuery"
          placeholder="藏品名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="TXID">
        <el-input
          v-model="form.txid"
          @keyup.enter.native="onQuery"
          placeholder="请输入TXID"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onQuery">搜索</el-button>
        <el-button type="info" @click="onClear">清除</el-button>
        <el-button @click="onAdd">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border stripe>
      <el-table-column label="藏品名称" prop="work_name" align="center"></el-table-column>
      <el-table-column label="TXID" prop="txid" align="center">
        <template slot-scope="scope">
          <CopyTxid :txid="scope.row.txid" />
        </template>
      </el-table-column>
      <el-table-column label="作者" prop="creator_name" align="center"></el-table-column>
      <el-table-column label="数量" prop="count" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleClickRemove(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="新增缓存" :visible.sync="addCache" width="30%" @close="clearCache">
      <div>
        <el-input
          v-model="addTxid"
          placeholder="输入TXID"
          size="normal"
          @keyup.enter.native="handleClickTxid"
          clearable
        >
          <el-button slot="append" @click="handleClickTxid">查询</el-button>
        </el-input>
      </div>
      <div v-if="searchTxidCache.name">
        {{ `${searchTxidCache.name} | 铸造份数:${searchTxidCache.count}` }}
      </div>
      <span slot="footer">
        <el-button type="primary" @click="handleConfirmAddCacheTxid" :loading="addLoading">
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCacheTxidList, addCacheTxid, delCacheTxid, getCacheTxidName } from "@/api/nft.js";
import CopyTxid from "@/components/CopyTxid.vue";
export default {
  components: {
    // ScopeTable,
    CopyTxid,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      form: {},
      list: [],
      addCache: false,
      addLoading: false,
      addTxid: "",
      searchTxidCache: {},
    };
  },

  mounted() {
    this.fetchList();
  },
  methods: {
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.form = {};
      this.fetchList();
    },
    clearCache() {
      this.addCache = false;
      this.addTxid = "";
      this.searchTxidCache = {};
    },
    onAdd() {
      this.addCache = true;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchList();
    },
    async fetchList() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
        txid: this.form.txid,
        work_name: this.form.name,
      };
      try {
        const { data } = await getCacheTxidList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
        this.list = [];
      }
    },
    async handleClickRemove(row) {
      // console.log(row);
      try {
        const { data } = await delCacheTxid({ txid: row.txid });
        this.fetchList();
      } catch (error) {
        console.log(error);
      }
    },
    async handleClickTxid() {
      // console.log(row);
      try {
        const { data } = await getCacheTxidName({ txid: this.addTxid, source: "points_draw" });
        this.searchTxidCache = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async handleConfirmAddCacheTxid() {
      if (this.addLoading) {
        return;
      }
      if (!this.addTxid) {
        this.$message.warning("请输入TXID");
        return;
      }
      this.addLoading = true;
      try {
        await addCacheTxid({ txid: this.addTxid });
        this.$message.success("新增缓存成功");
        this.fetchList();
        this.addLoading = false;
        this.addCache = false;
      } catch (error) {
        this.addLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
